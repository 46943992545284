// Modal

.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  z-index: 1060 !important;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch !important;
  background-color: rgba($black, .7) !important;
}

.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0 !important;
  background: transparent;
  border: 0 !important;
  padding: 0 !important;
  outline: none;
  transform: scale(0);
  transition: all 600ms ease;

  &--after-open {
    transform: scale(1);
  }

  &--before-close {
    transform: scale(0);
  }

  .ScrollbarsCustom {
    &-Wrapper {
      right: 0 !important;
    }

    &-Track {
      right: -1.7rem !important;
      width: .7rem !important;
      background: none !important;
      height: 100% !important;
      top: 0 !important;

      @include media-breakpoint-up(sm) {
        right: -2.2rem !important;
      }
    }

    &-Thumb {
      background: $colorGrayDark !important;
    }
  }

  .modal-wrapper {
    .modal-body {
      .modal-content {

      }
    }
  }

  .close {
    position: absolute;
    bottom: calc(100% + .6rem);
    left: calc(100% + .6rem);
    opacity: 1 !important;
    z-index: 9;
    cursor: pointer;
    display: inline-block;
    line-height: 1em;
    transition: opacity 300ms ease;

    &:hover {
      opacity: .7 !important;
    }

    img {
      width: 3rem;
    }
  }
}
