// Mixins

@mixin clearfix() {
  &:after {
    content: '';
    width: 100%;
    display: table;
    clear: both;
  }
}

@mixin background-alpha($color, $alpha) {
  $rgba: rgba($color, $alpha);
  $ie-hex-str: ie-hex-str($rgba);
  background-color: $color;
  background-color: $rgba;
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
  zoom: 1;
}

@mixin placeholderColor($color) {
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder { color: $color; }
  &::-webkit-input-placeholder  { color: $color; }
}

@mixin placeholder() {
  &::-moz-placeholder {@content}
  &:-ms-input-placeholder {@content}
  &::-webkit-input-placeholder {@content}
}

@mixin backgroundGradient($color1, $color2) {
  background: $color1 !important;
  background: -moz-linear-gradient(top,  $color1 0%, $color2 100%) !important;
  background: -webkit-linear-gradient(top,  $color1 0%,$color2 100%) !important;
  background: linear-gradient(to bottom,  $color1 0%,$color2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$color1, endColorstr=$color2,GradientType=0 ) !important;
}

@mixin backgroundGradientCentral($color1, $color2) {
  background: $color1 !important;
  background: -moz-linear-gradient(top,  $color1 0%, $color2 50%, $color1 100%) !important;
  background: -webkit-linear-gradient(top,  $color1 0%,$color2 50%,$color1 100%) !important;
  background: linear-gradient(to bottom,  $color1 0%,$color2 50%,$color1 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$color1, endColorstr=$color1,GradientType=0 ) !important;
}

@mixin textShadowGold() {
  text-shadow: .3rem 0 0 #e9d255, -.3rem 0 0 #e9d255, 0 .3rem 0 #e9d255, 0 -.3rem 0 #e9d255, .2rem .2rem #e9d255, -.2rem -.2rem 0 #e9d255, .2rem -.2rem 0 #e9d255, -.2rem .2rem 0 #e9d255;
}

@mixin textShadowDiff() {
  text-shadow: .3rem 0 0 #fba425, -.3rem 0 0 #fba425, 0 .3rem 0 #fba425, 0 -.3rem 0 #fba425, .2rem .2rem #fba425, -.2rem -.2rem 0 #fba425, .2rem -.2rem 0 #fba425, -.2rem .2rem 0 #fba425;
}
