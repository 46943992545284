// Header

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 3rem;
  font-style: italic;
  padding: 2rem;
  z-index: 200;

  @include media-breakpoint-up(lg) {
    padding: 2.5rem 3rem;
  }

  &__left {
    width: 20%;
  }

  &__right {
    width: 80%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__rules {
    position: relative;
    color: inherit !important;
    text-decoration: none !important;
    display: inline-block;
    line-height: 1em;
    padding-right: .3rem;

    &:before {
      content: '';
      position: absolute;
      bottom: 5%;
      left: 0;
      width: 100%;
      height: 1px;
      background: url('#{$cdnLink}/img/gradient-text-1.png') 0 0/100% 100% no-repeat;
    }
  }

  &__user {
    display: inline-block;
    padding-right: .3rem;
    // max-width: 22rem;
  }

  &__coupon {
    display: inline-block;
    padding-right: .3rem;

    &:before {
      content: '|';
      font-style: normal;
      margin: 0 1rem;

      @include media-breakpoint-up(lg) {
        margin: 0 2.5rem;
      }
    }

    .coupon {
      margin-left: .5rem;

      img {
        height: 3rem;
      }
    }
  }
}
