// Buttons

.btn-yellow {
  color: $black !important;
  font-size: 2.5rem;
  display: inline-block;
  text-decoration: none !important;
  padding: .5rem;
  border-radius: 1.5rem;
  min-width: 18rem;
  text-align: center;
  line-height: 1em;
  @include backgroundGradientCentral(#c39435, #aa612c);

  @include media-breakpoint-up(lg) {
    min-width: 21rem;
    font-size: 4rem;
  }

  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    padding: .8rem 2rem .6rem;
    @include textShadowGold;
    @include backgroundGradient(#f5cd37, #fba425);
  }

  &.disabled {
    opacity: .4;
    pointer-events: none;
  }
}
