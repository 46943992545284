.modal-theme-default {
  width: 85% !important;
  max-width: 972px;
  max-height: 80vh;

  @include media-breakpoint-up(sm) {
    width: 75% !important;
  }

  @include media-breakpoint-up(lg) {
    width: 85% !important;
  }

  .modal-decor-left, .modal-decor-right {
    position: absolute;
    bottom: -2rem;
  }

  .modal-decor-left {
    left: -11rem;
    z-index: 3;
    width: 17.9rem;
  }

  .modal-decor-right {
    right: -11rem;
    z-index: 1;
    width: 18.4rem;
  }

  .modal-wrapper {
    position: relative;
    z-index: 2;
    padding: .9rem;
    border-radius: 1.5rem;
    @include backgroundGradientCentral(#c39435, #aa612c);

    .modal-title {
      position: absolute;
      bottom: 100%;
      left: 0;
      text-align: center;
      font-size: 5rem;
      font-style: italic;
      width: 100%;
      line-height: 1.3em;
      margin: 0;
    }

    .modal-body {
      border-radius: 1rem;
      padding: 2rem 2.5rem 2rem 2rem;
      @include backgroundGradient(#c1c0c0, #afafaf);

      @include media-breakpoint-up(sm) {
        padding: 3rem 3.5rem 3rem 3rem;
      }

      .ScrollbarsCustom {
        height: 50vh !important;

        &-Content {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .modal-content {
        font-size: 3rem;
        font-style: italic;

        p {
          margin: 0;

          &~p {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
