// Sweetalert2

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba($black, .7) !important;
}

.swal2-popup {
  width: 100% !important;
  height: auto !important;
  max-width: 45rem !important;
  max-height: 40rem !important;
  padding: .9rem !important;
  border-radius: 1.5rem !important;
  @include backgroundGradientCentral(#c39435, #aa612c);

  .swal2-title {
    position: absolute;
    text-align: center;
    bottom: calc(100% - 1rem);
    left: 0;
    text-align: center;
    font-size: 5rem;
    font-style: italic;
    width: 100%;
    line-height: 1.3em;
    font-weight: normal;
    text-transform: uppercase;
    display: block !important;
    margin: 0 0 1rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    span {
      display: inline-block;
      padding: 0 1rem;
    }
  }

  .swal2-close {
    width: auto;
    height: auto;
    color: transparent;
    outline: none;
    bottom: calc(100% + .6rem);
    left: calc(100% + .6rem);
    top: auto;
    right: auto;

    img {
      width: 3rem;
    }
  }

  .swal2-content {
    border-radius: 1rem;
    height: 100%;
    color: $black;
    @include textShadowGold;
    @include backgroundGradient(#c1c0c0, #afafaf);
  }

  &.popup-error {
    .swal2-content {
      position: relative;
      padding: 2rem 2rem 2.5rem;

      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        bottom: -4.9rem;
        right: calc(100% - 12rem);
        width: 20rem;
        height: 15.3rem;
        background: url('#{$cdnLink}/img/popup-warning-chibi.png') 0 0/100% 100% no-repeat;

        @include media-breakpoint-up(lg) {
          width: 26.6rem;
          height: 20.4rem;
        }
      }
    }
  }

  &.popup-success {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 55rem;
      height: 52rem;
      max-height: 90vh;
      transform: translate(-50%, -50%);
      background: url('#{$cdnLink}/img/popup-success-bg.png') 0 0/100% 100% no-repeat;

      @include media-breakpoint-up(lg) {
        width: 60rem;
        height: 56.7rem;
      }
    }

    .swal2-content {
      position: relative;
      padding: 2rem;

      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        bottom: -2rem;
        left: calc(100% - 8rem);
        width: 13.6rem;
        height: 15.3rem;
        background: url('#{$cdnLink}/img/popup-success-chibi.png') 0 0/100% 100% no-repeat;

        @include media-breakpoint-up(lg) {
          bottom: -3rem;
          width: 18.1rem;
          height: 20.4rem;
        }
      }
    }
  }

  .popup-alert {
    &__content {
      .btn-yellow {
        position: absolute;
        bottom: -.9rem;
        left: 50%;
        z-index: 3;
        transform: translate(-50%, 50%);
      }
    }

    &__message {
      font-size: 3rem;
      font-style: italic;
      text-transform: uppercase;
      line-height: 1.8em;
      margin: 0;

      @include media-breakpoint-up(lg) {
        line-height: 1.5em;
      }

      .diff-text {
        @include textShadowDiff;
      }
    }

    &__icon {
      font-size: 11rem;
      line-height: 1em;
      margin: 1rem 0 0;
    }

    &__gift {
      margin-top: 1.5rem;

      &--icon {
        display: inline-block;
        max-width: 8.8rem;
      }

      &--name {
        font-size: 2.4rem;
        font-style: italic;
        text-transform: uppercase;
        line-height: 1.3em;
        margin: 1rem 0 0;

        @include media-breakpoint-up(lg) {
          line-height: 1.5em;
        }
      }
    }
  }
}
