// Typography

.text-gray-bg {
  background: url('#{$cdnLink}/img/gradient-text-1.png') 0 0/150% 150% no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.7em;
}

.text-gold-bg {
  background: url('#{$cdnLink}/img/gradient-text-2.png') 0 0/150% 150% no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.7em;
}

.odometer.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition-duration: 8s !important;
  -moz-transition-duration: 8s !important;
  -ms-transition-duration: 8s !important;
  -o-transition-duration: 8s !important;
  transition-duration: 8s !important
}

.odometer-inside, .odometer-digit-spacer, .odometer-value {
  font-family: $font-family-sans-serif !important;
  color: #aba9a8 !important;
  background: url('#{$cdnLink}/img/gradient-text-1.png') 0 0/150% 150% no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
