// Home

.page-home {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .home {
    &__center {
      width: 34%;
      height: 100%;
      text-align: center;
      padding: 0 2%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__left, &__right {
      width: 30%;
      height: 100%;
      padding-top: 40vh;

      &--text {
        font-size: 6rem;
        font-style: italic;
        line-height: 1em;
        padding-right: .3rem;
        margin: 0;

        @include media-breakpoint-up(lg) {
          font-size: 7rem;
        }
      }
    }

    &__left {
      text-align: right;
      position: relative;
      overflow: hidden;

      &--chibi {
        position: absolute;
        bottom: -4rem;
        right: 0;
        height: 20rem;

        @include media-breakpoint-up(lg) {
          bottom: -5.5rem;
          height: 32.9rem;
        }
      }
    }

    &__right {
      text-align: left;

      .coupon {
        font-size: 6.5rem;
        line-height: 1em;
        display: flex;
        align-items: center;
        margin-bottom: .5rem;
        font-family: $font-family-bebas;

        @include media-breakpoint-up(lg) {
          font-size: 8rem;
          margin-bottom: 1rem;
        }

        &>span {
          color: #aba9a8 !important;
          background: url('#{$cdnLink}/img/gradient-text-1.png') 0 0/150% 150% no-repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        img {
          height: 6.5rem;
          display: inline-block;
          margin-right: .3rem;

          @include media-breakpoint-up(lg) {
            height: 7.6rem;
          }
        }
      }
    }
  }

  @import 'components/wheel';
}
