// Fonts

@font-face {
  font-family: 'ALPMassimoBold';
  src: url('/fonts/ALPMassimoBoldItalic.eot?#iefix') format('embedded-opentype'),
       url('/fonts/ALPMassimoBoldItalic.otf') format('opentype'),
       url('/fonts/ALPMassimoBoldItalic.woff') format('woff'),
       url('/fonts/ALPMassimoBoldItalic.ttf') format('truetype'),
       url('/fonts/ALPMassimoBoldItalic.svg#ALPMassimoBoldItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ALPMassimoBold';
  src: url('/fonts/ALPMassimoBold.eot?#iefix') format('embedded-opentype'),
       url('/fonts/ALPMassimoBold.otf') format('opentype'),
       url('/fonts/ALPMassimoBold.woff') format('woff'),
       url('/fonts/ALPMassimoBold.ttf') format('truetype'),
       url('/fonts/ALPMassimoBold.svg#ALPMassimoBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BebasNeue';
  src: url('/fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
       url('/fonts/BebasNeue-Regular.woff') format('woff'),
       url('/fonts/BebasNeue-Regular.ttf') format('truetype'),
       url('/fonts/BebasNeue-Regular.svg#BebasNeue-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DBHelvethaicaMonX';
  src: url('/fonts/DBHelvethaicaMonX-85Blk.eot?#iefix') format('embedded-opentype'),
       url('/fonts/DBHelvethaicaMonX-85Blk.woff') format('woff'),
       url('/fonts/DBHelvethaicaMonX-85Blk.ttf') format('truetype'),
       url('/fonts/DBHelvethaicaMonX-85Blk.svg#DBHelvethaicaMonX-85Blk') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DBHelvethaicaMonX';
  src: url('/fonts/DBHelvethaicaMonX-86BlkIt.eot?#iefix') format('embedded-opentype'),
       url('/fonts/DBHelvethaicaMonX-86BlkIt.woff') format('woff'),
       url('/fonts/DBHelvethaicaMonX-86BlkIt.ttf') format('truetype'),
       url('/fonts/DBHelvethaicaMonX-86BlkIt.svg#DBHelvethaicaMonX-86BlkIt') format('svg');
  font-weight: normal;
  font-style: italic;
}
