.wheel {
  &__pieces {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      width: 37.6rem;
    }

    li {
      width: 15.4rem;
      height: 15.4rem;
      position: relative;
      border-radius: 1.9rem;
      overflow: hidden;
      max-width: 47%;
      box-shadow: 0 0 10px $black;
      background: url('#{$cdnLink}/img/bg-prize.png') 0 0/100% 100% no-repeat;

      @include media-breakpoint-up(md) {
        width: 12rem;
        height: 12rem;
        border-radius: 1rem;
      }

      @include media-breakpoint-up(lg) {
        width: 17.2rem;
        height: 17.2rem;
        border-radius: 1.9rem;
      }

      &:nth-child(1) {
        margin-bottom: 5%;
        margin-right: 5%;
        order: 1;
      }

      &:nth-child(2) {
        order: 2;
      }

      &:nth-child(3) {
        order: 4;
      }

      &:nth-child(4) {
        order: 3;
        margin-right: 5%;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: url('#{$cdnLink}/img/border-prize.png') 0 0/100% 100% no-repeat;
      }

      &.blur {
        opacity: .5;
      }

      &.active {
        box-shadow: 0 0 10px yellow;

        &:before {
          background: url('#{$cdnLink}/img/border-prize-active.png') 0 0/100% 100% no-repeat;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      h4 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: $white;
        font-weight: normal;
        font-size: 3rem;
        font-style: italic;
        text-align: center;
        text-transform: uppercase;
        padding: 1rem;
        background: rgba($black, .5);
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 300ms ease;
      }

      &:hover {
        h4 {
          opacity: 1;
        }
      }
    }
  }

  &__actions {
    margin-top: 2rem;
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(lg) {
      margin-top: 3rem;
    }
  }
}
