// Base

html {
  font-size: 6px;

  @include media-breakpoint-up(md) {
    font-size: 8px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 10px;
  }
}

* {
  outline: none !important;
}

body {
  letter-spacing: -.04em;
  background-color: $black;
}

#main {
  position: relative;
  min-height: 100vh;
  background: $white url('#{$cdnLink}/img/background.png') center center/cover no-repeat;
}
